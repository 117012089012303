@import "normalize.css";
@import "react-tabs/style/react-tabs.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "react-toastify/dist/ReactToastify.css";
@import "trix/dist/trix.css";

/* --- start table css --- */

.red {
  color: orangered;
}
.green {
  color: green;
}

.noselect,
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

#TableSearchInput {
  width: 153px;
  height: 21px;
  border: 0;
  background: white;
  padding: 1px;
  font-size: 13px;
  padding-left: 5px;
  margin-top: 6px;
}

.inf-icon {
  background: silver;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
}

#mag-glass-icon {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: -16px;
  pointer-events: none;
}

#mag-glass-circ {
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  width: 7px;
  height: 7px;
  border: 1px solid gray;
  border-radius: 50px;
}

#mag-glass-hand {
  display: block;
  position: absolute;
  top: 12px;
  left: 4px;
  width: 7px;
  height: 2px;
  background: gray;
  transform: rotate(45deg);
}

/* Remove ugly Chrome border */
#TableSearchInput:focus {
  outline: none;
  border: 1px solid silver;
}

.faded {
  color: silver;
}

#ZapiszButton:hover {
  border-color: #ccc;
}

.zebra-stripe {
  background: #f9f9f9;
}

.zebra-white {
  background: #ffffff;
}

.sticky-shadow {
  box-shadow: 3px 1px 3px 0px #e3e3e3;
}

.sticky-cell {
  position: sticky;
  border-right: 1px solid #eee;
  box-shadow: 3px 1px 3px -2px #e3e3e3;
  text-align: center;
  left: 0;
}

.inline-block {
  display: inline-block;
  margin-right: 4px;
}

.tcl {
  position: relative;
  display: inline-block;
  font-size: 12px;
  line-height: 8px;
  margin-left: 3px;
  cursor: pointer;
}

.corner-cell {
  border-top-left-radius: 8px;
}

.tbc {
  /* table-container */
  margin: 16px;
  overflow-x: auto;
}

.twh {
  /* table-wrapper-header */
  display: flex;
  flex-direction: row;
  width: 100%;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
}

.twr {
  /* .table-wrapper-column */
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-column {
  padding: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-cell {
  padding: 16px;
  text-overflow: ellipsis;
  line-height: 25px;
}

.table-cell-small {
  padding: 6px 16px;
}

.table-cell:nth-child(1) {
  text-align: center;
  z-index: 1;
}

.table-cell.selected {
  background: #bfe5ff !important;
}

.table-cell.selected {
  background: #bfe5ff !important;
}

.cell-wrapper {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.cell-wrapper:hover {
  /*background: #f5fcff;*/
}

.pagination-button {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}

.pagination-button.hidden {
  display: none;
}

.button-visible {
  display: inline-block;
}

.pagination-button:hover {
  background: #c8f5ff;
}

.pagination-button.active {
  border: 1px solid #005aff;
}

.selected-row {
  background: yellow;
}

/* --- end table css --- */

.css-2b097c-container {
  width: 15rem;
}

.css-yk16xz-control {
  border-radius: 8px !important;
}

.react-trix-suggestions {
  top: auto !important;
  bottom: 70px;
  left: auto !important;
  right: 100px;
  max-height: 300px;
  overflow: auto;
}

.react-trix-suggestions a {
  color: black;
  text-decoration: none;
}
.react-trix-suggestions a:hover {
  color: #005aff;
  text-decoration: underline;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* UPDATED Trix styles */
trix-toolbar .trix-button-row {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
trix-toolbar .trix-button-group:not(:first-child) {
  margin-left: unset;
}
figure.attachment.attachment--preview img {
  width: 100%;
  height: unset;
}
